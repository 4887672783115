export const SIGNATORY_INITIAL_INDEX = 0;

export const MAX_MOTHERS_MAIDEN_NAME_LENGTH = 30;
export const MAX_LENGTH_MARITAL_OTHERS_STATUS = 20;

export const STEP_1 = 1;
export const STEP_2 = 2;
export const STEP_3 = 3;
export const STEP_4 = 4;
export const STEP_5 = 5;
